<template>
<div v-if="loading">
    <Loading :active.sync="loading" color="#042446"></Loading>
</div>
<div class="scope" v-else>
    <b-row v-if="$vssWidth < 540" style="marginBottom:3%">
        <b-col id="selectCategory" class="selectCategory">
            <div><b>หมวดหมู่</b></div>
            <b-form-group>
                <b-form-checkbox-group v-model="tag" id="checkboxes-4">
                    <div class="categoryList">
                        <b-form-checkbox v-for="(tag,index) in allTag" :key="index" :value="tag.tag_id">{{tag.tag_name}}</b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <div class="searching">
                <b-button class="search" @click="tag.length>0?filterByTag(1):getAllNovel(1);">ค้นหา</b-button>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="2" id="selectCategory" class="selectCategory" v-if="$vssWidth > 540">
            <div><b>หมวดหมู่</b></div>
            <b-form-group>
                <b-form-checkbox-group v-model="tag" id="checkboxes-4">
                    <div class="categoryList" v-for="(tag,index) in allTag" :key="index">
                        <b-form-checkbox :value="tag.tag_id">{{tag.tag_name}}</b-form-checkbox>
                    </div>
                </b-form-checkbox-group>
            </b-form-group>
            <div class="searching">
                <b-button class="search" @click="tag.length>0?filterByTag(1):getAllNovel(1)">ค้นหา</b-button>
            </div>
        </b-col>
        <b-col class="novelLists" v-if="isLoadData" style="margin-top: auto;margin-bottom: auto;">
            <div class="text-center">
                <b-spinner variant="primary" style="color:#042446 !important" label="Text Centered"></b-spinner>
            </div>
        </b-col>
        <b-col class="novelLists" v-else>
            <b-row class="novelCard" v-if="allnovel_count == 0">
                <h3 class="elem-center">ไม่พบนิยายที่คุณค้นหา</h3>
            </b-row>
            <a class="text-decoration-none text-default-n" v-else v-for="(novel,index) in allNovel" :key="index" :href="'/novel/' + novel.novel_id" v-on:click="goToNovel(novel.novel_id, $event)">
                <b-row class="novelCard">
                    <b-col cols="3" class="image text-center">
                        <b-img-lazy :src="novel.novel_cover" fluid class="novelCover" @error.native="img_load_default_cover"></b-img-lazy>
                    </b-col>
                    <b-col cols="9" class="novelInfo">
                        <div><b>{{novel.novel_title}}</b></div>
                        <div>จำนวนตอนทั้งหมด: {{novel.last_ep}}</div>
                        <div>หมวดหมู่ <br />
                        <span class="category mr-2" v-for="tag_id in novel.novel_tag.split(',')" :key="tag_id">
                            {{((allTag.filter(tag => tag.tag_id === tag_id)).map(tag => tag.tag_name))[0]}}
                        </span>
                        </div>
                        <a-divider class="divider" v-if="$vssWidth > 540" />
                        <div class="novel_desc" v-html="novel.novel_desc"></div>
                        <div class="review"><span>จำนวนผู้เข้าชม {{novel.novel_read_count}} วิว</span><span class="comment">ความคิดเห็น {{novel.count_comment}} รายการ</span></div>
                    </b-col>
                </b-row>
            </a>
        </b-col>
    </b-row>
    <b-pagination-nav pills align="center" :link-gen="linkGen" :number-of-pages="(allnovel_count/15)" use-router v-model="currentPage" @input="tag.length>0?filterByTag(currentPage):getAllNovel(currentPage)"></b-pagination-nav>
    <back-to-top bottom="50px" right="50px">
        <button type="button" class="btn btn-info btn-to-top">
            <b-icon-chevron-up></b-icon-chevron-up>
        </button>
    </back-to-top>
</div>
</template>

<script>
import axios from 'axios'
import router from '../../router';
import {
    mapGetters,
    mapActions
} from 'vuex'
var queryParams = new URLSearchParams(window.location.search);
export default {
    computed: {
        ...mapGetters({
            loading: 'GET_LOADING',
            mode: 'GET_MODE'
        })
    },
    data() {
        return {
            isLoadData: false,
            currentPage: 1,
            allNovel: [{
                novel_cover: "",
                novel_title: "",
                novel_tag: "",
                novel_desc: "",
                novel_read_count: 0,
                count_comment: 0,
                last_ep: 0
            }],
            allTag: [{
                tag_id: 0,
                tag_name: ""
            }],
            tag: [],
            allnovel_count: 15
        };
    },
    mounted() {
        //document.title = 'นิยายทั้งหมด';
        this.SET_LOADING_STATUS(true)
        queryParams.set("page", "1");
        history.replaceState(null, null, "?" + queryParams.toString());

        this.getAllNovel(1);
    },
    watch: {
        mode() {
            this.checkMode()
        }
    },
    methods: {
        ...mapActions(['SET_LOADING_STATUS']),
        img_load_default_cover(event){
            //console.log("Image broken -> Load default")
            event.target.src = require("@/assets/default_cover.jpg")
        },
        goToNovel(novel_id, e) {
            e.preventDefault();
            router.push('/novel/' + novel_id)
            return false;
        },
        linkGen(pageNum) {
            return {
                path: '/allNovel',
                query: {
                    page: pageNum
                }
            }
        },
        async getAllNovel(currentPage) {
            this.isLoadData = true
            this.setParam(currentPage)
            this.currentPage = currentPage

            try {
                await axios.get(`https://api2.novelrealm.com/module/allnovel&page=` + this.currentPage)
                    .then((res) => {
                        this.allnovel_count = res.data.allcount
                        this.allNovel = res.data.novel
                        this.allTag = res.data.tag
                        this.SET_LOADING_STATUS(false)
                    })
                setTimeout(() => {
                    this.checkMode()
                }, 5);
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
            this.backToTop()
            this.isLoadData = false
        },
        async filterByTag(currentPage) {
            this.isLoadData = true
            this.setParam(currentPage)
            this.currentPage = currentPage
            const formData = new FormData();
            this.tag.forEach(element => {
                formData.append('tags[]', element);
                formData.append('page', this.currentPage);
            });
            try {
                await axios.post('https://api2.novelrealm.com/module/allnovel', formData)
                    .then((res) => {
                        this.allnovel_count = res.data.allcount
                        this.allNovel = res.data.novel
                        this.backToTop()
                        this.isLoadData = false
                    })
                setTimeout(() => {
                    this.checkMode()
                }, 5);
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        setParam(currentPage) {
            if (currentPage == 1) {
                queryParams.set("page", "1");
                history.replaceState(null, null, "?" + queryParams.toString());
            }
        },
        backToTop() {
            document.body.scrollTop = 0; //go to top
            document.documentElement.scrollTop = 0; //go to top
        },
        checkMode() {
            if (this.mode == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('selectCategory').classList.remove('darkmode')
            document.getElementsByClassName('novelCard').forEach(element => {
                element.classList.remove('darkmode')
            });
            document.getElementsByClassName('review').forEach(element => {
                element.classList.remove('darkmodeText')
            });
        },
        darkmode() {
            document.getElementById('selectCategory').classList.add('darkmode')
            document.getElementsByClassName('novelCard').forEach(element => {
                element.classList.add('darkmode')
            });
            document.getElementsByClassName('review').forEach(element => {
                element.classList.add('darkmodeText')
            });
        }
    },
};
</script>

<style scoped>
.btn-to-top {
    width: 2.8em;
    height: 2.8em;
    border-radius: 50%;
    font-size: 1.2em;
    background-color: #F5D087;
    border: none;
}

.btn-to-top:hover {
    background-color: rgba(250, 199, 98, 0.658);
}

.text-default-n{
    color: inherit;
}

.btn-to-top:active {
    background-color: #FAC762 !important;
    border: none !important;
}

.row {
    display: -webkit-box;
}

.scope {
    padding: 1% 10% 1% 10%;
}

.novelLists {
    margin-left: 7%;
}

.novelCard,
.selectCategory {
    padding: 2%;
    height: fit-content;
}

.novelCard {
    background-color: #ffffff;
    margin-bottom: 2%;
    transform: translateZ(0px);
}

.novelCard:hover {
    transform: scale(1.01);
    cursor: pointer;
    box-shadow: 0px 3px 7px rgba(4, 36, 70, 0.5);
}

.selectCategory {
    margin-right: -4%;
    border-radius: 6px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position: sticky;
    top: 11%;
}

.categoryList {
    margin-top: 5px;
    font-size: 14px;
}

.search {
    font-size: 14px;
    font-weight: bold;
    background-color: #F5D087;
    color: white;
    border: none;
    border-radius: 8px;
    width: 70%;
}

.searching {
    text-align: center;
}

.search:focus,
.search:hover,
.search:active {
    background-color: #FAC762;
    box-shadow: none;
}

.category {
    color: #ffffff;
    background-color: #EE806B;
    padding: 3px 7px;
    border-radius: 5px;
}

.novelCover {
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
}

.divider {
    margin: 15px 0;
}

.novel_desc {
    height: 4.8em;
    overflow: hidden;
    font-size: 15px;
    line-height: 1.65em;
}

.review {
    text-align: right;
    margin-top: 11px;
    font-size: 13px;
    color: #042446;
}

.comment {
    margin-left: 3%;
}

/* @media screen and (max-width: 768px) {
    
} */
@media screen and (max-width: 500px) {
    .scope {
        padding: 2% 5% 3% 5%;
    }

    .search {
        width: 30%;
    }

    .novelCard {
        padding: 10px 0px;
    }

    .novelLists {
        margin-left: 0%;
        padding: 0;
    }

    .image {
        margin-left: -6px;
        margin-right: -17px;
    }

    .novelCover {
        width: 60px;
        height: 85px;
    }

    .novelInfo {
        padding-right: 0px;
    }
}

.elem-center{
    margin: auto;
}

@media screen and (min-width: 1441px) {
    .scope {
        padding: 1% 20% 1% 20%;
    }
}

.darkmode {
    background-color: #35363A;
}

.darkmodeText {
    color: #ffffff;
}

.darkmode h3 {
    color: #ffffff;
}
</style>
